<template>
  <div>
    <h1>登录</h1>
    <form @submit.prevent="login">
      <label for="username">用户名:</label>
      <input type="text" id="username" v-model="username" required><br>
      <label for="password">密码:</label>
      <input type="password" id="password" v-model="password" required><br>
      <button type="submit" :disabled="isLoggingIn">{{ loginButtonText }}</button>
    </form>
    <p v-if="errorMessage" style="color: red;">{{ errorMessage }}</p>
  </div>
</template>

<script setup>
import { http } from '@/utils/http';
import { ref, onBeforeMount } from 'vue';
import { useRouter } from 'vue-router';

const username = ref('');
const password = ref('');
const loginButtonText = ref('登录');
const isLoggingIn = ref(false);
const errorMessage = ref('');
const router = useRouter();

onBeforeMount(() => {
  const route = router.currentRoute.value;
  if (route.query.username && route.query.password) {
    username.value = route.query.username;
    password.value = route.query.password;
    login();
  }
});

const login = async () => {
  if (isLoggingIn.value) return;
  isLoggingIn.value = true;
  loginButtonText.value = '登录中...';

  try {
    const response = await http.post('/api/auth/login', {
      username: username.value,
      password: password.value
    });

    const token = response.data.token;
    if (token) {
      localStorage.setItem('token', token);
      router.push('/uniswap');
    } else {
      errorMessage.value = response.errorMessage;
    }

    isLoggingIn.value = false;
    loginButtonText.value = '登录';
  } catch (error) {
    console.error(error);
    errorMessage.value = '登录失败，请重试';
    isLoggingIn.value = false;
    loginButtonText.value = '登录';
  }
};
</script>
