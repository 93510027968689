<template>
  <div class="sidebar">
    <div class="logo" @click="iconClicked">
        <div>BLOCK</div>
        <div>HUNTER</div>
    </div>
    <ul class="menu">
      <li v-for="(menuItem, index) in menuItems" :key="index" @click="handleItemClick(menuItem)">
        {{ menuItem.title }}
      </li>
    </ul>
  </div>
</template>

<script setup>
import { defineProps } from 'vue';
import { defineEmits } from 'vue'; // 引入 defineEmits
import { useRouter } from 'vue-router';
// 声明 emits
const emits = defineEmits(['menuSelected']);
const router  = useRouter()
// 菜单项数据
defineProps(['menuItems']);


// 处理菜单项点击事件
const handleItemClick = (menuItem) => {
  // 触发菜单选中事件，通知父组件
  emits('menuSelected', menuItem.route);
};

const iconClicked = () => {
  router.push('/')
}

</script>

<style scoped>
.sidebar {
  width: 120px;
  background-color: #333;
  color: #fff;
  height: calc(100vh);
}

.logo {
  padding: 8px 4px;
  text-align: center;
  border: 1px solid gold;
  cursor: pointer;
}

.menu {
  list-style: none;
  padding: 0;
}

.menu li {
  padding: 10px;
  cursor: pointer;
}

.menu li:hover {
  background-color: #555;
}
</style>
