<template>
    <div class="dashboard">
        <div>
            <div class="build">
                <div class="build-icon">👷</div>
                <div class="build-icon-big">👷</div>
                <div class="build-icon">👷</div>
            </div>

            <div>
                <h1>BLOCK HUNTER </h1>
                <h2>Real-time on-chain monitoring</h2>
            </div>
            <div>
                <a href="https://joycode.one" target="_blank"> @joycode</a>
            </div>
            <div class="features">
            </div>

        </div>
    </div>
</template>

<script setup>

</script>

<style scoped>
.dashboard {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: calc(100vh - 40px);
    a {
        text-decoration: none;
    }
    overflow: hidden;
}

.features {
    margin-top: 30px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px; /* 设置网格项之间的间隙 */
}

.feature-item {
    background-color: black;
    height: 150px;
    width: 300px;
    border-radius: 4px;
    color: white;
    padding: 16px;
    cursor: pointer;
}

.feature-item-title {
    font-size: 24px;
}

.feature-item-description {
    margin-top: 16px;
    color: gray;
    line-height: 1.5;
}

.build {
    margin-top: 16px;
    display: flex;
    justify-content: center;
    flex-direction: row; 
    align-items: flex-end;
    height: 100px;
}

.build-tips {
    margin-top: 8px;
    color: red;
    font-style: italic;
}

.build-icon {
    font-size: 80px;
}

.build-icon-big {
    font-size:  100px;
}



</style>