
// src/utils/http.js
import axios from 'axios';

// 创建axios实例
export const http = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  timeout: 10000, 
});

// 请求拦截器
http.interceptors.request.use(config => {
    // 在发送请求之前做些什么，例如设置token
    const token = localStorage.getItem('token')
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    config.headers['Content-Length'] = 'application/json'
    return config;
}, error => {
// 对请求错误做些什么
    return Promise.reject(error);
});

// 响应拦截器
http.interceptors.response.use(response => {
    // 对响应数据做点什么
    return response;
}, error => {
    if (error.response && error.response.status === 401) {
        // 如果收到 401 响应，则重定向到登录页面
        window.location.href = '/login';
    }
    // 对响应错误做点什么
    return Promise.reject(error);
});
