<template>
  <div id="app">
    <MenuSidebar :menuItems="menuItems" @menuSelected="handleMenuSelected" />
    <main>
      <router-view />
    </main>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import MenuSidebar from './components/MenuSidebar.vue';
import { useRouter } from 'vue-router'; // 引入useRouter

// 菜单项数据
const menuItems = ref([
  { title: 'Uniswap', route: '/uniswap' },
  { title: 'Airdrop', route: '/airdrop' },
  { title: 'Wallet', route: '/wallet' },
  { title: 'Contract', route: '/contract' },
]);

const router = useRouter(); // 使用useRouter

// 处理菜单选中事件
const handleMenuSelected = (route) => {
  router.push(route); // 使用router.push来改变路由
};
</script>

<style>
#app {
  display: flex;
}

body {
  margin: 0px;
  
}

main {
  flex: 1;
  padding: 4px;
  width: calc(100vw - 120px);
}
</style>
