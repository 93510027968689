<template>
    <div>
      <h1>注册</h1>
      <form @submit.prevent="register">
        <label for="username">用户名:</label>
        <input type="text" id="username" v-model="username" required><br>
        <label for="password">密码:</label>
        <input type="password" id="password" v-model="password" required><br>
        <button type="submit">注册</button>
      </form>
      <p v-if="errorMessage" style="color: red;">{{ errorMessage }}</p>
    </div>
  </template>
  
  <script setup>
  import { ref } from 'vue';
  import { useRouter } from 'vue-router'; 
  const username = ref('');
  const password = ref('');
  const errorMessage = ref('');
  import {http} from '@/utils/http'; // 导入Axios实例
  const router = useRouter();
  
  async function register() {
      http.post('/api/auth/register', {
        username: username.value,
        password: password.value
      })
      .then(response => {
        console.log(response.data)
        if (response.status===201) {
          router.push({
            path: '/login',
            query: { username: username.value, password: password.value }
          })
        } else {
          errorMessage.value = response.text();
        }

      })
      .catch (error => {
        const msg = error.response.data
        if (msg) {
          errorMessage.value = error.response.data;
        } else {
          errorMessage.value = "注册时发生错误";
        }
      })
 
  }
</script>


<style>
  /* 添加一些基本样式 */
  form {
    margin-top: 20px;
  }

  label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }

  input[type="text"],
  input[type="password"],
  button[type="submit"] {
    padding: 8px;
    margin-bottom: 10px;
    width: 100%;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
  }

  button[type="submit"] {
    background-color: #007bff;
    color: white;
    cursor: pointer;
  }

  button[type="submit"]:hover {
    background-color: #0056b3;
  }

  p.error-message {
    color: red;
    margin-top: 10px;
  }
</style>
