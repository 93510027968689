import { createApp } from 'vue';
import App from './App.vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
// 导入createRouter和createWebHistory，不再直接导入router
import { createRouter, createWebHistory } from 'vue-router';
import UniswapPool from './components/UniswapPool.vue';
import LoginView from './components/LoginView.vue'
import RegisterView from './components/RegisterView.vue';
import DashboardView from './components/DashboardView.vue';
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

const routes = [
  { path: '/uniswap', component: UniswapPool },
  { path: '/login', component: LoginView },
  { path: '/register', component: RegisterView },
  { path: '/', component: DashboardView }, // 默认路由
];

// 使用createRouter创建router实例
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

library.add(fas)
const options = {
  // You can set your default options here
};

const app = createApp(App);

// 使用router
app.use(router);
app.use(Toast, options);
app.component('font-awesome-icon', FontAwesomeIcon)
app.mount('#app');
