
<template>
    <div class="uniswap">
        <table class="uniswap-pool-table"> 
            <thead>
                <th>index</th>
                <th>dexscreener</th>
                <th>version</th>
                <th>tokentxns</th>
                <th>token0</th>
                <th>token1</th>
                <th>updatedAt</th>
                <th>creator</th>
                <th>first tx date</th>
                <th>first tx value</th>
                <th>current balance</th>
            </thead>
            <tbody>
                <tr v-for="(pool, index) in poolData" :key="pool.pairAddress" >
                    <td>{{ index }}</td>
                    
                    <td style="font-family: monospace;">
                      <!-- https://dexscreener.com/ethereum/0x6d27a7c4214f46eab4b67c402810b125ce72face -->                        
                      <a class="uniswap-theme" :href="`https://dexscreener.com/ethereum/${pool.pairAddress}?t=${Date.now()}`" target="_blank">{{ formatAddress(pool.pairAddress) }}</a>
                        <button class="btn-copy" @click="copyToClipboard(pool.pairAddress)">
                          <font-awesome-icon :icon="['fas', 'copy']" /> 复制
                        </button>
                    </td>
                    <td>
                      <a v-if="pool.version==='V2'" class="uniswap-theme" :href="`https://v2.info.uniswap.org/pair/${pool.pairAddress}`" target="_blank">{{ pool.version }}</a>
                      <a v-if="pool.version==='V3'" class="uniswap-theme" :href="`https://info.uniswap.org/#/pools/${pool.pairAddress}`" target="_blank">{{ pool.version }}</a>
                    </td>
                    <td style="font-family: monospace;">
                      <a class="uniswap-theme" :href="`https://etherscan.io/address/${pool.pairAddress}#tokentxns`" target="_blank">tokentxns</a>
                    </td>

                    <td style="font-family: monospace;">
                        <a class="uniswap-theme" :href="`https://etherscan.io/address/${pool.token0}`" target="_blank">{{ pool.token0Symbol}}</a>
                        <button class="btn-copy" @click="copyToClipboard(pool.pairAddress)">
                          <font-awesome-icon :icon="['fas', 'copy']" /> 复制
                        </button>
                    </td>

                    <td style="font-family: monospace;">
                        <a class="uniswap-theme" :href="`https://etherscan.io/address/${pool.token1}`" target="_blank">{{ pool.token1Symbol }}</a>
                        <button class="btn-copy" @click="copyToClipboard(pool.pairAddress)">
                          <font-awesome-icon :icon="['fas', 'copy']" /> 复制
                        </button>
                    </td>

                    <td style="font-family: monospace;"> {{displayTimeFromNow(pool.createdAt) }}</td>
                    <td style="font-family: monospace;">
                      <!-- 使用 v-if 指令来判断 pool.walletInfo 是否存在 -->
                      <a v-if="pool.walletInfo" class="uniswap-theme" :href="`https://etherscan.io/address/${pool.walletInfo.creatorAddress}`" target="_blank">
                        {{ formatAddress(pool.walletInfo.creatorAddress) }}
                      </a>
                      <button v-if="pool.walletInfo" class="btn-copy" @click="copyToClipboard(pool.pairAddress)">
                          <font-awesome-icon :icon="['fas', 'copy']" /> 复制
                        </button>
                      <!-- 可以在这里使用 v-else 或 v-else-if 来处理 walletInfo 为空的情况 -->
                      <div class="no-data" v-else>N/A</div>
                    </td>

                    <td style="font-family: monospace;"> 
                      <div v-if="pool.walletInfo">{{displayTimeFromNow2(pool.walletInfo.firstTransactionAt) }}</div>
                      <div class="no-data" v-else>N/A</div>
                    </td>

                    <td style="font-family: monospace;"> 
                      <div v-if="pool.walletInfo">{{pool.walletInfo.firstTransactionValue }} ETH</div>
                      <div class="no-data" v-else>N/A</div>
                    </td>

                    <td style="font-family: monospace;"> 
                      <div v-if="pool.walletInfo">{{pool.walletInfo.creatorWalletBalance}} ETH</div>
                      <div class="no-data" v-else>N/A</div>
                    </td>
                </tr>
            </tbody>
        </table>
        <!-- 分页组件 -->
        <div class="pagination" v-if="totalPages > 1">
          <button @click="prevPage" :disabled="currentPage === 1" class="pagination-btn">上一页</button>
          <span class="pagination-info">第 {{ currentPage }} 页 / 共 {{ totalPages }} 页</span>
          <button @click="nextPage" :disabled="currentPage === totalPages" class="pagination-btn">下一页</button>
        </div>
    </div>
</template>

<script setup>
import { useToast } from "vue-toastification";

import { onMounted, ref } from 'vue';
import {http} from '@/utils/http'; // 导入Axios实例
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
dayjs.extend(relativeTime);

const poolData = ref([]);
const currentPage = ref(1)
const totalPages = ref(0)
const toast = useToast();

onMounted(() => {
    fetchPoolData(currentPage.value)
})

const fetchPoolData = async (page) => {
    http.get(`/api/pools?page=${page}&limit=23`)
    .then(response => {
        console.table(response.data)
        // poolData.value = response.data
        console.table(response.data);
        poolData.value = response.data.pools; // 更新数据
        currentPage.value = response.data.currentPage; // 更新当前页数
        totalPages.value = response.data.totalPages; // 更新总页数
    })
    .catch(error => {
        console.log(error)
    })
}
const displayTimeFromNow = (createAt) => {
  const now = dayjs();
  const updated = dayjs(createAt);
  const diffInSeconds = now.diff(updated, 'second');
  const diffInMinutes = now.diff(updated, 'minute');
  const diffInHours = now.diff(updated, 'hour');

  if (diffInSeconds < 60) {
    return `${diffInSeconds}s`;
  } else if (diffInMinutes < 60) {
    return `${diffInMinutes}m`;
  } else if (diffInHours < 24) {
    return `${diffInHours}h`;
  } else {
    return updated.format('YYYY-MM-DD');
  }
}

const displayTimeFromNow2 = (createdAt) => {
  const now = dayjs();
  const updated = dayjs(createdAt);
  const diffInSeconds = now.diff(updated, 'second');
  const diffInMinutes = now.diff(updated, 'minute');
  const diffInHours = now.diff(updated, 'hour');
  const diffInDays = now.diff(updated, 'day');
  const diffInYears = now.diff(updated, 'year');

  if (diffInSeconds < 60) {
    return `${diffInSeconds}s`;
  } else if (diffInMinutes < 60) {
    return `${diffInMinutes}min`;
  } else if (diffInHours < 24) {
    return `${diffInHours}h`;
  } else if (diffInDays < 365) {
    return `${diffInDays}d`;
  } else {
    return `${diffInYears}y`;
  }
};


// 上一页
const prevPage = () => {
  if (currentPage.value > 1) {
    fetchPoolData(currentPage.value - 1);
  }
}

// 下一页
const nextPage = () => {
  if (currentPage.value < totalPages.value) {
    fetchPoolData(currentPage.value + 1);
  }
}

// 定义格式化地址的函数
function formatAddress(pairAddress) {
  if (!pairAddress) {
    return '';
  }
  const start = pairAddress.slice(0, 6);
  const end = pairAddress.slice(-4);
  return `${start}...${end}`.toUpperCase();
}

const copyToClipboard = (text) => {
  navigator.clipboard.writeText(text).then(() => {
      // 可选：复制成功后的操作，如显示通知或反馈
      // console.log('内容已复制到剪贴板');
      toast.success(text + "\n已复制到剪贴板");

    }).catch(err => {
      // 处理可能的错误
      console.error(err);
    });
}

</script>

<style  scoped>
.uniswap {
    width: 100%;
    max-height: calc(100vh - 20px);
    overflow-y: auto;
}

.uniswap-pool-table {
  width: 100%;
  border-collapse: collapse;
  table-layout: auto;
}

.uniswap-pool-table th, .uniswap-pool-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.uniswap-pool-table th {
  background-color: #e33176;
  color: white;
}
.uniswap-pool-table td {
    /* display: flex; */
}

.uniswap-pool-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.uniswap-pool-table tr:hover {
  background-color: #ddd;
}

.uniswap-theme {
  color: #e33176;
}

.uniswap-theme:visited {
  color: purple; /* 设置链接被点击后的颜色为紫色 */
}


.pagination {
  margin-top: 20px;
  text-align: center;
}

.pagination-btn {
  padding: 5px 10px;
  margin: 0 5px;
  cursor: pointer;
  background-color: #e33176;
  color: white;
  border: none;
  border-radius: 5px;
}

.pagination-btn:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.pagination-info {
  margin: 0 10px;
  font-size: 14px;
}

.btn-copy {
  border: none;
  border-radius: 4px;
  color: gray;
  margin-left: 16px;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s ease, visibility 0s 0.3s; 
  cursor: pointer;
}

td:hover .btn-copy {
  visibility: visible; /* 鼠标悬停时显示 */
  opacity: 1;
  transition-delay: 0s; /* 立即显示 */
}

.no-data {
  opacity: 0.3;
}
</style>